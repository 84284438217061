import styled from 'styled-components'

export const Wrapper = styled.div`
	padding: 2rem 0;
`

export const Grid = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 8fr;
	gap: 1.2rem 1.2rem;

	@media (max-width: 960px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 680px) {
		grid-template-columns: 1fr;
	}
`

export const Item = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 15px;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

	h4 {
		font-size: 16px;
		text-align: center;
		color: #212121;
	}

	p {
		color: #707070;
	}
`

export const Content = styled.div`
	padding: 1rem 0;
`

export const Stats = styled.div`
	display: flex;
	align-items: center;
	text-align: center;

	div {
		display: flex;
		&:first-child {
			margin-right: 0.5rem;
		}

		img {
			margin: 0;
		}

		span {
			color: #000;
			margin-left: 0.5rem;
		}
	}
`
export const Button = styled.button`
	cursor: pointer;
	border-radius: 4px;
	align-content: center;
	padding: 0.7rem 2.5rem;
	margin-left: 6rem;
	border: none;
	-webkit-appearance: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #fff;
	background-image: linear-gradient(to right, #47d6cf 0%, #12d0a4 51%, #13b3ca 100%);

	&:focus {
		outline: none;
	}

	&:disabled {
		background: gray;
	}
`