import React from 'react'
import { Header } from 'components/theme'
import { Container } from 'components/common'
import TeamAnimation from 'assets/illustrations/team-animation'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

export const Team = () => (
	<Wrapper>
		<Header />
		<IntroWrapper as={Container}>
			<Details>
				<h1>Our Best Self @ Work</h1>
				<p>Passion brings out the best in us, we are a creative team focused on
                delivering the best services for our clients through authentic stories
				that resonate with their audience.</p>
			</Details>
			<Thumbnail>
				<TeamAnimation alt="Endslate Team" />
			</Thumbnail>
		</IntroWrapper>
	</Wrapper>
)