import React from 'react'
import { Layout, SEO } from 'components/common'
import { Team, Founders, Careers } from 'components/about'

export default () => (
	<Layout>
		<SEO />
		<Team />
		<Founders />
		<Careers />
	</Layout>
)