import React from 'react'
import { Container, Card } from 'components/common'
import Jonathan from 'assets/icons/jonathan-guzman.svg'
import Victor from 'assets/icons/victor-samudio.svg'
import Instagram from 'assets/icons/instagram.svg'
import Linkedin from 'assets/icons/linkedin.svg'
import Github from 'assets/icons/github.svg'
import { Wrapper, Grid, Item, Content} from './styles'

export const Founders = () => (
	<Wrapper as={Container}>
		<h2>Our Team</h2>
		<Grid>
			<Item>
				<Card>
					<Content>
						<img src={Jonathan} alt="Brand Identity Services by Endslate" />
						<h4>Jonathan Guzman, Co-Founder & CEO</h4>
						<div style={{textAlign:'center'}}>
							<a href="https://www.instagram.com/panamericano" target="_blank" rel="noopener noreferrer nofollow">
								<img style={{width:'12px', paddingRight:'10px'}} 
									src={Instagram} 
									alt='CEO Instagram'
								/>
							</a>
							<a href="https://www.linkedin.com/in/panamericano" target="_blank" rel="noopener noreferrer nofollow">
								<img style={{width:'12px', paddingRight:'10px'}} 
									src={Linkedin} 
									alt='CEO Linkedin'
								/>
							</a>
							<a href="https://www.github.com/panamericano" target="_blank" rel="noopener noreferrer nofollow">
								<img style={{width:'12px', paddingRight:'6px'}} 
									src={Github} 
									alt='CEO Github'
								/>
							</a>
						</div>
						<p>Jonathan's passion for growing brands users love through storytelling
						has led his career across various online industries, from eCommerce to Digital Media.
						With a background in Technology and Marketing, he focuses on driving growth and
						 creating engaging experiences for our clients. Prior to Endslate, Jonathan worked
						  for dot-coms, start-ups, and corporations like The Walt Disney Company.</p>
					</Content>
				</Card>
			</Item>
			<Item>
				<Card>
					<Content>
						<img src={Victor} alt="eCommerce Strategy by Endslate" />
						<h4>Victor Samudio, Co-Founder & COO</h4>
						<div style={{textAlign:'center'}}>
							<a href="https://www.instagram.com/thecoolvic/" target="_blank" rel="noopener noreferrer nofollow">
								<img style={{width:'12px', paddingRight:'10px'}} 
									src={Instagram} 
									alt='COO Instagram'
								/>
							</a>
							<a href="https://www.linkedin.com/in/victor-samudio-a7227252" target="_blank" rel="noopener noreferrer nofollow">
								<img style={{width:'12px', paddingRight:'10px'}} 
									src={Linkedin} 
									alt='COO Linkedin'
								/>
							</a>
						</div>
						<p>Victor’s ambition has driven him on a journey where he co-owned and operated marketing, operations, sales
						 and procurrement for his last company, Long Beach Oilers. He took his background in Marketing and years of experience from
						  sales at Verizon, where he perfected his craft and became one of the top sales people at Verizon Wireless. 
						At Endslate, he focuses on Brand Management, Client Success and Partnerships.
						
						</p>
					</Content>
				</Card>
			</Item>
		</Grid>
	</Wrapper>
)