import React from 'react'
import { Container } from 'components/common'
import jobs from 'assets/icons/jobs.svg'
import { Wrapper, SkillsWrapper, Details, Thumbnail, Button } from './styles'

export const Careers = () => (
	<Wrapper>
		<SkillsWrapper as={Container}>
			<Thumbnail>
				<img src={jobs} alt="Digital Marketing Agency Careers" />
			</Thumbnail>
			<Details>
				<h1>Open Roles</h1>
				<p>We are committed to building a diverse team and a work environment that’s
				 inclusive of people of all backgrounds. Join Endslate and help businesses infuse creativity 
				 into every part of their Digital Presence.</p>
				<a href="https://www.angel.co/company/endslate" target="_blank" rel="noopener noreferrer nofollow">
					<Button>
                    Careers
					</Button>
				</a>
			</Details>
		</SkillsWrapper>
	</Wrapper>
)