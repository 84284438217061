import styled from 'styled-components'

export const Wrapper = styled.div`
	background-size: contain;
	background-position: left top;
	background-repeat: no-repeat;
`

export const SkillsWrapper = styled.div`
	padding: 4rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 960px) {
		flex-direction: column;
	}
`

export const Details = styled.div`
	flex: 1;
	padding-left: 2rem;

	@media (max-width: 960px) {
		padding-left: unset;
		width: 100%;
	}

	h1 {
		margin-bottom: 2rem;
		font-size: 26pt;
		color: #212121;
	}

	p {
		margin-bottom: 2.5rem;
		font-size: 16pt;
		font-weight: normal;
		line-height: 1.3;
		color: #707070;
	}
`

export const Thumbnail = styled.div`
	flex: 1;

	@media (max-width: 960px) {
		width: 100%;
		margin-bottom: 2rem;
	}

	img {
		width: 100%;
	}
`

export const Button = styled.button`
	cursor: pointer;
	border-radius: 4px;
	align-content: center;
	padding: 0.7rem 2.5rem;
	border: none;
	text-decoration: none;
	-webkit-appearance: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #fff;
	background-image: linear-gradient(to right, #47d6cf 0%, #12d0a4 51%, #13b3ca 100%);

	&:focus {
		outline: none;
	}

	&:disabled {
		background: gray;
	}
	a:link {
		color: #fff;
	}
	a:visited {
		color: #fff;
	}
`